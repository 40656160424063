export const NavItems = [
    {
        name: "Home", 
        type: "single-level",
        partialPage: "Home",
        path: "/",
        icon: "HomeIcon",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        children: [
            {
                id: 1,
                name: "",
                partialPage: "",
                path: "/",
                icon: "Icon"
            },            
        ]                          
    },
    {
        name: "About", 
        type: "single-level",
        partialPage: "About",
        path: "/about",
        icon: "AboutIcon",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        children: [
            {
                id: 1,
                name: "",
                partialPage: "",
                path: "/",
                icon: "Icon"
            },            
        ]                          
    },
    {
        name: "Downloads",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "",
        children: [
            {
                id: 1,
                name: "Plugins",
                partialPage: "Plugins",
                path: "/plugins",
                icon: "HomeIcon",
                children: [ // WIP: Another layer down? Doesn't work yet
                    {
                        id: 1,
                        name: "Another Level",
                        partialPage: "AnotherLevel",
                        path: "/",
                        icon: "HomeIcon",
                    }
                ]
            },
            {
                id: 2,
                name: "Themes",
                partialPage: "Themes",
                path: "/themes",
                icon: "HomeIcon",
                children: [ // WIP: Another layer down? Doesn't work yet
                    {
                        id: 1,
                        name: "Another Level",
                        partialPage: "AnotherLevel",
                        path: "/",
                        icon: "HomeIcon",
                    }
                ]
            },
        ]                          
    },
    {
        name: "Projects",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        type: "multi-level",
        partialPage: "Projects",
        path: "/projects",
        icon: "CloudUploadIcon",
        children: [
            {
                id: 1,
                name: "Code",
                partialPage: "Code",
                path: "/code",
                icon: "CodeIcon",
                children: [ // WIP: Another layer down? Doesn't work yet
                    {
                        id: 1,
                        name: "Another Level",
                        partialPage: "AnotherLevel",
                        path: "/",
                        icon: "HomeIcon",
                    }
                ]
            },
            {
                id: 2,
                name: "Musia",
                partialPage: "MusiA",
                path: "/musia",
                icon: "MusicNoteIcon",
                children: [ // WIP: Another layer down? Doesn't work yet
                    {
                        id: 1,
                        name: "Another Level",
                        partialPage: "AnotherLevel",
                        path: "/",
                        icon: "HomeIcon",
                    }
                ]
            },
        ]                          
    },
    {
        name: "Pages", 
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "",
        displayTitle: true, // WIP: To determine whether to show the heading for this section of the nav
        children: [
            {
                id: 1,
                name: "Categories",
                partialPage: "Categories",
                path: "/Categories",
                icon: "CategoryIcon"
            },
            {         
                id: 2,               
                name: "Posts",
                partialPage: "Post",
                path: "/Posts",
                icon: "ArticleIcon"
                
            },
            {
                id: 3,
                name: "Tags",
                partialPage: "Tags",
                path: "/Tags",
                icon: "TagIcon"
            },   
            {
                id: 4,
                name: "Users",
                partialPage: "",
                path: "/@",
                icon: "UserIcon"
            },            
        ]                          
    },
    {
        name: "TestNet",
        type: "multi-level",
        partialPage: "",
        path: "",
        icon: "",
        children: [
            {
                id: 1,
                name: "Earn",
                partialPage: "Earn",
                path: "/Earn",
                icon: "Icon"
            },
            {
                id: 2,
                name: "Buy",
                partialPage: "Buy",
                path: "/Buy",
                icon: "BuyIcon"
            },
            {
                id: 3,
                name: "Sell",
                partialPage: "Sell",
                path: "/Sell",
                icon: "SellIcon"
            },
            {
                id: 4,
                name: "Swap",
                partialPage: "Swap",
                path: "/Swap",
                icon: "SwapIcon"
            },
        ]
    },
]